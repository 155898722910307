import cinnamonSticks from '../../images/products/cinnamon-stick.jpg'
import cinnamonPowder from '../../images/products/cinnamon-powder.jpg'
import clove from '../../images/products/cloves.jpg'
import cardamon from '../../images/products/cardamom.jpg'
import blackPepperSeeds from '../../images/products/peper.jpg'
import blackPepperPowder from '../../images/products/black-pepper-powder.jpg'
import whitPepperPowder from '../../images/products/white-pepper-powder.jpg'
import cinnamonStickPouch from '../../images/products/cinnamon-pouch.jpg'
import pepperSeedPouch from '../../images/products/pepper-pouch.jpg'
import clovePouch from '../../images/products/clove-pouch.jpg'
import cardamonPouch from '../../images/products/cardamom-pouch.jpg'
import cinnamonStickContainer from '../../images/products/cinnamon-container.jpg'
import pepperSeedContainer from '../../images/products/pepper-container.jpg'
import clovesContainer from '../../images/products/clove-container.jpg'
import cardamonContainer from '../../images/products/cardamom-container.jpg'

const ProductData = {
  reusablePouches: [
    {
        imageSrc:cinnamonStickPouch,
        title: 'Cinnamon Sticks',
        content: 'Elevate your dishes with our reusable Ceylon Cinnamon pouch – authentic, exceptional flavor, and eco-friendly.',
      },
      {
        imageSrc:pepperSeedPouch,
        title: 'Black Pepper Seeds',
        content: 'Discover our reusable Black Pepper Seeds pouch - a flavorful journey to culinary excellence and well-being.',
      },
      {
        imageSrc:clovePouch,
        title: 'Cloves Pouch',
        content: 'Experience the world\'s finest cloves in our reusable pouch—globally prized for intense aroma and high oil content.',
      },
      {
        imageSrc:cardamonPouch,
        title: 'Cardamon Pouch',
        content: 'Elevate your cuisine with our reusable Ceylon Cardamon pouch, celebrated for its distinct fragrance and refined taste.',
      }
  ],
  bulkQuantities: [
    {
      imageSrc: cinnamonSticks,
      title: 'Cinnamon Sticks',
      content: 'Ceylon Cinnamon, the true variety, boasts exceptional flavor and unrivaled health benefits worldwide',
    },
    {
      imageSrc: cinnamonPowder,
      title: 'Cinnamon Powder',
      content: 'Ethically harvested Ceylon Cinnamon Quills are carefully dried and transformed into a fine powder'
    },
    {
      imageSrc: blackPepperSeeds,
      title: 'Black Pepper Seeds',
      content: 'Ceylon black pepper is more than a spice. It\'s a journey of taste and well being',
    },
    {
      imageSrc: blackPepperPowder,
      title: 'Black Pepper Powder',
      content: 'Elevate your culinary journey with premium Ceylon black pepper powder. Make the wise choice today!',
    },
    {
      imageSrc: whitPepperPowder,
      title: 'White Pepper Powder',
      content: 'Handpicked white pepper, finely processed into powder, hygienically packed for premium quality',
    },
    {
      imageSrc: clove,
      title: 'Cloves',
      content: 'Sri Lankan cloves, prized globally for their intense aroma and high oil content.',
    },
    {
      imageSrc: cardamon,
      title: 'Cardamon',
      content: 'Differing from other cardamom types, our Ceylon Cardamon boasts a distinct fragrance and refined taste',
    },
  ],
  biodegradableContainers: [
    {
      imageSrc:cinnamonStickContainer,
      title: 'Cinnamon Sticks',
      content: 'Discover eco-friendly and reusable Ceylon Cinnamon in biodegradable containers - authentic flavor, sustainable choice.',
    },
    {
      imageSrc:pepperSeedContainer,
      title: 'Black Pepper Seeds',
      content: 'Embark on a flavor-rich and sustainable journey with our Ceylon Black Pepper Seeds in biodegradable containers.',
    },
    {
      imageSrc:clovesContainer,
      title: 'Cloves Container',
      content: 'Experience the globally sought-after Sri Lankan cloves, now in eco-friendly biodegradable containers, preserving their intense aroma.',
    },
    {
      imageSrc:cardamonContainer,
      title: 'Cardamon Container',
      content: 'Elevate your dishes sustainably with our Ceylon Cardamom in biodegradable containers, celebrated for its unique fragrance and refined taste.',
    }
  ],
};

export { ProductData };
