import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo-full-invert.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-48`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-4`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-1 font-medium text-secondary-100 leading-loose`}
  }

  .iconStyle {
    ${tw`hover:border-gray-300 hover:text-gray-600 cursor-pointer pr-4`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>
          <LinksContainer>
            <Link href="#">Home</Link>
            <Link href="#categories">Categories</Link>
            <Link href="#products">Products</Link>
            <Link href="#about">About Us</Link>
            <Link href="#contactus">Contact Us</Link>
          </LinksContainer>
          <SocialLinksContainer>
          <Card>
              <span className="textContainer">
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/organex.ceylon">
                  <FontAwesomeIcon icon={faFacebook} size={"3x"} className="iconStyle" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/organexceylon/">
                  <FontAwesomeIcon icon={faInstagram} size={"3x"} className="iconStyle" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/organexceylon/">
                  <FontAwesomeIcon icon={faLinkedin} size={"3x"} className="iconStyle" href="#categories"/>
                </a>
              </span>
            </Card>
          </SocialLinksContainer>
          <CopyrightText>
            &copy;Copyright 2023, Organex Ceylon Delights (Pvt) ltd. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
