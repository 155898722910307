import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import styled from "styled-components";

const PostText = tw.div`flex-1 px-6 py-8` 
const PostTitle = tw.h6`font-bold text-4xl group-hocus:text-primary-800 transition duration-300 `;
const TextCenter = tw.div`text-center mt-48`
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`md:w-1/3 rounded-lg py-5 font-semibold`}
`

export default () => {
  return (
    <AnimationRevealPage>
      <TextCenter>
        <PostTitle>Nothing to see here</PostTitle>
        <PostText>Page you are trying to open does not exist. You may have mistyped<br/> the address, or the page has been moved to another URL.</PostText>
        <Link href={'/'}>Back to Home</Link>
      </TextCenter>
    </AnimationRevealPage>
  );
};
