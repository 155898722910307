import React, {useRef} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";



const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto pt-20 md:pt-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-4`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-1 font-medium text-secondary-100 leading-loose`}
  }

  .iconStyle {
    ${tw`hover:border-gray-300 hover:text-gray-600 cursor-pointer pr-4`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;


const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto px-24`;
const ColumnBase = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const SecondColumn = tw(ColumnBase)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(ColumnBase)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`



export default ({ 
    textOnLeft = true,
    heading = "Contact Us",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." 
}) => {

  const form = useRef();
  const recaptcha = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    }else{
      emailjs.sendForm('service_t9d8e84', 'template_tkirm4c', form.current, 'FEI41HN1rHzhgYzzq')
        .then((result) => {
          form.current.reset();
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }
    
  };

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />

        <Column>
            <Card>
              <span className="textContainer">
                <span className="title">{"Email Address"}</span>
                <p className="description">
                  {"hello@organexceylon.com"}
                </p>
              </span>
            </Card>
          </Column>
          <Column>
            <Card>
              <span className="textContainer">
                <span className="title">{"Phone Number"}</span>
                <p className="description">
                  {"+94 70 720089"}
                </p>
              </span>
            </Card>
          </Column>
          <Column>
            <Card>
              <span className="textContainer">
                <span className="title">{"Office Location"}</span>
                <p className="description">
                  {"Wennappuwa, Sri Lanka"}
                </p>
              </span>
            </Card>
          </Column>
          <Column>
            <Card>
              <span className="textContainer">
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/organex.ceylon">
                  <FontAwesomeIcon icon={faFacebook} size={"3x"} className="iconStyle" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/organexceylon/">
                  <FontAwesomeIcon icon={faInstagram} size={"3x"} className="iconStyle" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/organexceylon/">
                  <FontAwesomeIcon icon={faLinkedin} size={"3x"} className="iconStyle" href="#categories"/>
                </a>
              </span>
            </Card>
          </Column>
        
      </ThreeColumnContainer>
      <div>
      <TwoColumn>
        <SecondColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </SecondColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Form ref={form} onSubmit={sendEmail}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <Input type="text" name="full_name" placeholder="Full Name" />
              <Input type="text" name="subject" placeholder="Subject" />
              <Textarea name="message" placeholder="Your Message Here" />
              <ReCAPTCHA ref={recaptcha} sitekey={'6LeVPOwoAAAAABaneqm4EAn0PWnHYICMHBJd0F41'} />
              <SubmitButton type="submit">{"Submit"}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </div>
      <DecoratorBlob />
    </Container>
  );
};
