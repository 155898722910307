import React from 'react';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/HomeHero';
import Features from 'components/features/ProductCategories';
import MainFeature from 'components/features/Feature';
import MainFeature2 from 'components/features/TwoColSingleFeatureWithStats2.js';
import Products from 'components/grid/Products';
import Footer from 'components/footers/Footer';
import ContactDetails from 'components/cards/ContactUs';

import pouchImageSrc from 'images/spices/pouch.png';
import containerImageSrc from 'images/spices/container.png';
import bulkImageSrc from 'images/spices/bulk.png';

import spices3 from 'images/spices/spices-3.jpg'

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
          Love with the Essence of Ceylon
        </>
        }
        description={'Indulge in the flavors of Ceylon\'s finest creations, where every bite embodies the essence of love and kindness. Join us in spreading a plateful of positivity to the world'}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText='Contact Us'
        imageRounded={true}
        primaryButtonUrl={"#contactus"}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <MainFeature
        subheading={<Subheading>Established Since 2018</Subheading>}
        heading={
          <>
            Connecting Cultures through Deliciousness
          </>
        }
        description={
          <Description>
            To be the global beacon of exceptional taste and sustainability, 
            where the richness of Ceylon's finest flavors meets the world's highest standards. 
            <br />
            <br />
            We envision a planet united by premium quality, environmentally conscious food and 
            beverages that celebrate cultures, empower farmers, and nourish lives.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText='Latest Offers'
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <section id='categories'>
        <Features
          heading={
            <>
              Our Product Categories
            </>
          }
          cards={[
            {
              imageSrc: bulkImageSrc,
              title: <>Bulk <br/>Quantities</>,
              description: 'Substantial volume of spices that is suitable for distribution to wholesalers',
            },
            {
              imageSrc: pouchImageSrc,
              title: 'Reusable Pouches',
              description: 'Designed to be environmentally friendly and can use multiple times',
            },
            {
              imageSrc: containerImageSrc,
              title: 'Biodegradable Containers',
              description: 'Designed to break down naturally and decompose into environmentally friendly byproducts',
            },
          ]}
          imageContainerCss={tw`p-2!`}
          imageCss={tw`w-20! h-20!`}
        />
      </section>
      <section id='products'>
        <Products
          heading={
            <>
              Checkout our Products
            </>
          }
        />
      </section>
      <section id='about'>
        <MainFeature2
          subheading={<Subheading>A Reputed Brand</Subheading>}
          heading={
            <>
              Unraveling Our Origins
            </>
          }
          primaryButtonText='Order Now'
          primaryButtonUrl='https://order.now.com'
          imageInsideDiv={false}
          imageSrc= {spices3}
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />
      </section>
      <section id='contactus'>
        <ContactDetails description='Spice up your inquiries! Reach out to us for any questions or assistance. We value your feedback' />
      </section>
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      {/* <FloatingWhatsApp phoneNumber={'+94707200089'}/> */}
      <Footer />
    </AnimationRevealPage>
  );
};
